import Raven from '../../../services/Raven'
import { getCountryCode3, apiURL, convertCountryString } from '@GlobalHelpers'
import { isNumber } from '../../utils/Utils'
import Axios from 'axios'
import { getCustomerToken } from '../../utils/Customer'
import StoreAuth from '@Stores/User/StoreAuth'

const create = (baId, now = new Date()) => {
    const date = now.getUTCDate()
    const weekDay = now.getUTCDay() + 1
    const modeWeekDay = (date % weekDay) + 1
    const hash = baId
        .toString()
        .split('')
        .map(c => parseInt(c) % modeWeekDay)
        .join('')
    return `${hash}${weekDay}${date}`
}
/**
 *
 * @param {string|number} baId
 * @param {string} customerHref use hash only
 * @param {string} countryCode
 */
export const GetAllDataWithoutOrderHistory = (
    baId,
    customerHref,
    expand,
    callback
) => {
    const defaultParams = [
        'lsb',
        'boxProfile',
        'addressBook',
        'commission',
        'successTracker',
        'seminar',
        'facebookLogin'
    ]
    let expands = ''

    if (expand === true) {
        expands = defaultParams.join(',')
    } else {
        expands = [
            'boxProfile',
            'successTracker',
            'seminar',
            'lsb',
            'commission'
        ].join(',')
    }

    const params = {
        expand: expands,
        expandBoxProfile:
            'customer,metricsProfile,metricsProfileHistory,profilePicture,achievementsHistory,cumulativeMetricsProfile',
        expandSuccessTracker: 'metrics',
        country_code: getCountryCode3(),
        baId: baId
    }

    if (customerHref !== undefined) {
        params.customerHref = customerHref
    }

    let url = `https://member-calls2.unicity.com/adapter/dashboard`

    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${StoreAuth.token}`,
        'authorization-ushop': 'Bearer tVxbmKcjme'
    }

    Raven.get(url, params, { headers: headers })
        .then(data => {
            callback(true, data)
        })
        .catch(error => {
            callback(false, error)
        })
}

export const GetLsbAndCommission = (baId, customerHref, callback) => {
    const params = {
        // expand: 'lsb,commission',
        customerHref: customerHref,
        country_code: getCountryCode3(),
        baId: baId
    }

    let url = `https://member-calls2.unicity.com/adapter/etl/commission`

    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${StoreAuth.token}`,
        'authorization-ushop': 'Bearer tVxbmKcjme'
    }

    Raven.get(url, params, { headers: headers })
        .then(data => {
            callback(true, data)
        })
        .catch(error => {
            callback(false, error)
        })
}

export const GetOrderHistories = (callback, baId, periodStart, periodEnd, token, byPassCache = 0) => {

    const expands = ['orderHistory']

    // const params = {
    //     expand: 'orderHistory',
    //     expandOrderHistory: 'order,rma',
    //     dateCreated: `[${periodStart}|${periodEnd}]`,
    //     customer: 'me|sponsoredCustomers?type=Customer',
    //     customerHref: href
    // };
    const isDummy = localStorage.getItem("isDummy")
    const params = {
        baId: baId,
        ushopCountryCode: getCountryCode3(),
        ...(isDummy ? { isDummy } : {}),
    }

    if(byPassCache!==0) {
        params.byPassCache = byPassCache
    }


    let url = `https://member-calls2.unicity.com/etlV2/ordersHistory`;

    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${JSON.parse(localStorage.getItem('login-extends')).korea.token}`,
        'authorization-ushop': 'Bearer tVxbmKcjme'
    };

    Raven.get(url, params, { headers: headers })
        .then((data) => {
            callback(true, data)
        })
        .catch((error) => {
            callback(false, error)
        });
}


export const GetSponsoredCustomers = (
    callback,
    baId,
    customerHref,
    customerToken,
    countryCode,
    noCache
) => {
    const url = 'https://member-calls2.unicity.com/adapter/genealogy'
    const params = {
        baId: baId,
        token: create(baId),
        countryCode: convertCountryString(
            countryCode,
            'alpha-2',
            'alpha-3'
        ).toLowerCase()
    }

    if (noCache) {
        params.byPassCache = 1
    }

    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${customerToken}`,
        'authorization-ushop': 'Bearer tVxbmKcjme'
    }

    Axios.get(url, { params: params, headers: headers })
        .then(data => {
            callback(true, data.data)
        })
        .catch(error => {
            callback(false, error)
        })
}

export const EditInfo = (callback, fullUrlWithHref, data, token) => {
    const headers = {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`
    }

    Raven.post(fullUrlWithHref, data, { headers: headers })
        .then(data => {
            callback(true, data)
        })
        .catch(error => {
            callback(false, error)
        })
}

export const GetOrdersRmas = (callback, customerHref, period, token) => {
    const url = `https://hydra.unicity.net/v5a/customers/${customerHref}/ordersAndRmas`

    const params = {
        dateCreated: period,
        expand: 'order,rma',
        customer: 'me|sponsoredCustomers?type=Customer'
    }

    const headers = {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
        'x-application': 'ushop'
    }

    Raven.get(url, params, { headers: headers })
        .then(data => {
            callback(true, data)
        })
        .catch(error => {
            callback(false, error)
        })
}

export const GetTinyDataCustomer = (callback, baId) => {
    const url = apiURL.expandCustomer + baId + '?token=' + create(baId)

    Axios.get(url)
        .then(data => callback(true, data.data))
        .catch(error => callback(false, error))
}

export const GetDashboardData = (callback, baId, userCountryCode) => {
    const url = 'https://member-calls2.unicity.com/adapter/genealogy'
    const params = {
        baId: baId,
        token: create(baId),
        country_code: convertCountryString(
            userCountryCode,
            'alpha-2',
            'alpha-3'
        ).toLowerCase()
    }

    /* if(noCache) {
        params.byPassCache = 1
    } */

    const headers = {
        'Content-Type': 'application/json',
        'authorization-hydra': `Bearer ${getCustomerToken()}`,
        'authorization-ushop': 'Bearer tVxbmKcjme'
    }

    Axios.get(url, { params: params, headers: headers })
        .then(data => callback(true, data.data))
        .catch(error => callback(false, error))
}
